import './App.css';
import Sudoku from './Components/Sudoku';

function App() {
  return (
    <div className="App">
          <Sudoku />
    </div>
  );
}

export default App;
